var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-tree__item-h",style:({
        'top': _vm.virtualScroll ? _vm.itemTop : 'auto',
        'position': _vm.virtualScroll ? 'absolute' : 'relative'
    })},[_c('div',{staticClass:"c-tree__item",class:{
            'is-alias': _vm.isAlias,
            'is-navigated-object': _vm.navigated,
            'is-context-clicked': _vm.contextClickActive
        },on:{"!click":function($event){_vm.handleClick($event)},"!contextmenu":function($event){_vm.handleContextMenu($event)}}},[_c('view-control',{ref:"navUp",staticClass:"c-tree__item__view-control",attrs:{"control-class":'icon-arrow-nav-to-parent',"enabled":_vm.showUp},on:{"input":_vm.resetTreeHere},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}}),_vm._v(" "),_c('object-label',{ref:"objectLabel",style:({ paddingLeft: _vm.leftOffset }),attrs:{"domain-object":_vm.node.object,"object-path":_vm.node.objectPath,"navigate-to-path":_vm.navigationPath},on:{"context-click-active":_vm.setContextClickActive}}),_vm._v(" "),_c('view-control',{ref:"navDown",staticClass:"c-tree__item__view-control",attrs:{"control-class":'c-nav__down',"enabled":_vm.hasComposition && _vm.showDown},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }