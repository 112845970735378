var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-pane",class:{
        'l-pane--horizontal-handle-before': _vm.type === 'horizontal' && _vm.handle === 'before',
        'l-pane--horizontal-handle-after': _vm.type === 'horizontal' && _vm.handle === 'after',
        'l-pane--vertical-handle-before': _vm.type === 'vertical' && _vm.handle === 'before',
        'l-pane--vertical-handle-after': _vm.type === 'vertical' && _vm.handle === 'after',
        'l-pane--collapsed': _vm.collapsed,
        'l-pane--reacts': !_vm.handle,
        'l-pane--resizing': _vm.resizing === true
    }},[(_vm.handle)?_c('div',{staticClass:"l-pane__handle",on:{"mousedown":_vm.start}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"l-pane__header"},[(_vm.label)?_c('span',{staticClass:"l-pane__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_vm._t("controls"),_vm._v(" "),(_vm.collapsable)?_c('button',{staticClass:"l-pane__collapse-button c-icon-button",on:{"click":_vm.toggleCollapse}}):_vm._e()],2),_vm._v(" "),_c('button',{staticClass:"l-pane__expand-button",on:{"click":_vm.toggleCollapse}},[_c('span',{staticClass:"l-pane__expand-button__label"},[_vm._v(_vm._s(_vm.label))])]),_vm._v(" "),_c('div',{staticClass:"l-pane__contents"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }