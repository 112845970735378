var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-so-view",class:[
        _vm.statusClass,
        'c-so-view--' + _vm.domainObject.type,
        {
            'c-so-view--no-frame': !_vm.hasFrame,
            'has-complex-content': _vm.complexContent
        }
    ]},[_c('div',{staticClass:"c-so-view__header"},[_c('div',{staticClass:"c-object-label",class:[ _vm.statusClass ]},[_c('div',{staticClass:"c-object-label__type-icon",class:_vm.cssClass},[_c('span',{staticClass:"is-status__indicator",attrs:{"title":("This item is " + _vm.status)}})]),_vm._v(" "),_c('div',{staticClass:"c-object-label__name"},[_vm._v("\r\n                "+_vm._s(_vm.domainObject && _vm.domainObject.name)+"\r\n            ")])]),_vm._v(" "),_c('div',{staticClass:"c-so-view__frame-controls",class:{
                'c-so-view__frame-controls--no-frame': !_vm.hasFrame,
                'has-complex-content': _vm.complexContent
            }},[_c('div',{staticClass:"c-so-view__frame-controls__btns"},[_vm._l((_vm.statusBarItems),function(item,index){return _c('button',{key:index,staticClass:"c-icon-button",class:item.cssClass,attrs:{"title":item.name},on:{"click":item.callBack}},[_c('span',{staticClass:"c-icon-button__label"},[_vm._v(_vm._s(item.name))])])}),_vm._v(" "),_c('button',{staticClass:"c-icon-button icon-items-expand",attrs:{"title":"View Large"},on:{"click":_vm.expand}},[_c('span',{staticClass:"c-icon-button__label"},[_vm._v("View Large")])])],2),_vm._v(" "),_c('button',{staticClass:"c-icon-button icon-3-dots c-so-view__frame-controls__more",attrs:{"title":"View menu items"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showMenuItems($event)}}})])]),_vm._v(" "),_c('object-view',{ref:"objectView",staticClass:"c-so-view__object-view",attrs:{"show-edit-view":_vm.showEditView,"object-path":_vm.objectPath,"layout-font-size":_vm.layoutFontSize,"layout-font":_vm.layoutFont},on:{"change-action-collection":_vm.setActionCollection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }